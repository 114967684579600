import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackgroundImage from "gatsby-background-image";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import styled from "styled-components";

const HeroEnd = ({
	title,
	description1,
	description2,
	backgroundImage,
	isDarken,
}) => {
	const StyledBackground = styled(BackgroundImage)`
		&::before,
		&::after {
			filter: brightness(
				${({ isDarken }) => {
					return isDarken ? "75%" : "100%";
				}}
			);
		}
	`;
	return (
		<StyledBackground
			isDarken={isDarken}
			key={isDarken ? `dark` : `light`}
			Tag="section"
			className="hero-image-new py-5"
			style={heroImgStyle}
			fluid={backgroundImage}
		>
			<Container className="service-hero-container ">
				<Row
					className="align-items-center justify-content-lg-end h-100"
					style={heroImgStyle}
				>
					<Col className="text-lg-right" lg={8}>
						<h1 className="text-white ">{title}</h1>
						<p style={{ fontSize: "1.75rem" }} className="mb-lg-0 text-white">
							{description1}
						</p>
						{/* <Button
              as={Link}
              variant="secondary"
              size="lg"
              className="my-4 cta-btn-gtm"
              to="/contact-us"
            >
              Book a discovery meeting &#8594;
            </Button> */}
						<div className="my-4 text-right">
							<Button
								as={Link}
								variant="secondary"
								size="lg"
								className="cta-btn-gtm w-100 d-md-none"
								to="/contact-us"
							>
								Book a discovery meeting &#8594;
							</Button>
							<Button
								as={Link}
								variant="secondary"
								size="lg"
								className="cta-btn-gtm d-none d-md-inline-block"
								to="/contact-us"
							>
								Book a discovery meeting &#8594;
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</StyledBackground>
	);
};

const heroImgStyle = {
	position: "relative",
	backgroundPosition: "center center",
};

export default HeroEnd;
