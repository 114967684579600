import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const AccountantsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				andrewF: file(relativePath: { eq: "testimonials/Andrew-Fenton.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				dHill: file(relativePath: { eq: "testimonials/Peter Hill.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				kolos: file(relativePath: { eq: "testimonials/Kolos-Tapler.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title =
		"Accountants and Tax Advisors | Business & Executive Coaching in London";
	const description =
		"As a business and executive coach with a specialisation in supporting financial professionals, I understand that accountants and tax advisors are often the backbone of their small business clients' financial strategies.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const andrewF = data.andrewF.childImageSharp.gatsbyImageData;
	const dHill = data.dHill.childImageSharp.gatsbyImageData;
	const kolos = data.kolos.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Accountants And Tax Advisors",
				item: {
					url: `${siteUrl}/industries/accountants-and-tax-advisors`,
					id: `${siteUrl}/industries/accountants-and-tax-advisors`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/accountants-and-tax-advisors`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Accountants and Tax Advisors Executive Coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Accountants and Tax Advisors"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Accountants and tax advisors are often the unsung heroes behind
								the scenes, meticulously guiding small businesses through the
								maze of financial compliance and strategic planning. But when it
								comes to their own practices and growing their own business, who
								stands behind them offering support? They, too, need a sanctuary
								where they can seek guidance, a trusted, non-judgmental business
								and executive coach to illuminate their path as they encounter
								their unique set of opportunities and challenges.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Is professional business and executive coaching what you are
								after?
							</h3>
							<p>
								This is where the vital role of a nonjudgmental, trusted
								business and executive coach comes into play, offering a space
								for financial professionals to explore opportunities and
								confront challenges. In our coaching calls, we can discuss any
								key areas you want to focus on, including team recruitment,
								development, motivation, marketing strategies, and most
								crucially, establishing a work-life harmony. Confidentiality is
								a non-negotiable in these coaching partnerships, ensuring a
								secure environment for open discussion and strategic planning.
								With a vast collection of experience spanning almost four
								decades, including over seven years dedicated to coaching, I've
								collaborated with a variety of professionals navigating their
								own unique sectors' demands. Accountants and tax advisors juggle
								the challenges of client services with the operational demands
								of running their own practice, all whilst trying to grow their
								own firm.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Explore what the future holds for your firm…
							</h3>

							<p>
								You're an accountant or tax advisor with the expertise to guide
								small businesses through the fiscal world, but when it comes to
								navigating your own firm’s growth and challenges, where do you
								turn? You need a business coach, someone who’s not only versed
								in the numbers game but who also understands the nuances of your
								profession.
							</p>
							<p>Envision yourself:</p>
							<ul className="pl-4">
								<li>
									Growing your practice faster and more effectively than you
									might on your own.
								</li>
								<li>
									Facing each day with an increased sense of assurance and
									greater confidence.
								</li>
								<li>
									Enhancing your leadership abilities to inspire your team and
									attract new clients.
								</li>
								<li>
									Rekindling your passion for accountancy and learning to
									re-love what you do.
								</li>
							</ul>
							<p>
								The impact that these advancements could make on your practice,
								your clients, and your personal fulfilment is huge. But what
								hurdles are preventing you from making this vision a reality?
								Working with a business coach who can help you identify these
								challenges, overcome them, and set a clear course towards
								achieving your business goals.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What’s been stopping you?</h2>
							<ul className="pl-4">
								<li>
									Do you find yourself giving advice daily, yet ‘lonely in
									business’ yourself?
								</li>
								<li>
									Are decisions about the future of your practice causing you to
									pause and seek an external perspective?
								</li>
								<li>
									Do the challenges of team recruitment and motivation weigh you
									down?
								</li>
								<li>
									Have you noticed a dip in the vibrant enthusiasm that once
									characterised your professional services?
								</li>
								<li>
									Are you searching for that helping hand to rekindle ambition
									and direction within your accounting or tax advisory career?
								</li>
								<li>
									Could it be that you're in need of uplifting your service
									offerings but require a seasoned, impartial, and confidential
									executive coach to help bring them into focus?
								</li>
							</ul>
							<p>
								In the demanding and precise world of accounting and tax
								advisory, it's common for professionals to feel stuck. Left
								unchecked, such obstacles can impede not only your personal
								growth and job satisfaction but also the operational efficiency
								and expansion of your practice. It's time to address these
								issues head-on, with a trusted, non-judgmental business coach
								who understands the nuances of your industry. Let's work
								together to turn these challenges into opportunities for growth
								and success.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How business and executive coaching can help you within the
								financial sector
							</h2>
							<p>
								As a business and executive coach with a specialisation in
								supporting financial professionals, I understand that
								accountants and tax advisors are often the backbone of their
								small business clients' financial strategies. However, when it
								comes to seeking guidance for their own practices, they require
								a different kind of support – a dedicated, confidential space
								where they can explore opportunities, navigate challenges, and
								receive unbiased advice.
							</p>
							<p>
								This is why as your business coach, I provide flexible coaching
								sessions that hone in on vital areas for a thriving financial
								practice, including:
							</p>
							<ul className="pl-4">
								<li>
									Personal and Professional Growth for Accountants and Tax
									Advisors
								</li>
								<li>
									Leadership and Team Development Tailored to Financial Firms
								</li>
								<li>
									Business Strategy and Operational Management for Financial
									Services
								</li>
								<li>
									Career Advancement Strategies within the Financial Sector
								</li>
							</ul>
							<p>
								My approach to coaching is distinct from conventional methods,
								offering bespoke guidance that respects the confidentiality that
								is so important to financial professionals. With sessions
								tailored to meet the unique needs of your practice, we will
								address common themes such as team recruitment, motivation, and
								the all-important work-life balance, ensuring that your firm not
								only succeeds but leads in the financial field of accountants
								and tax advisors.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">My experience as a business coach</h2>
							<p>
								With a solid background in business management (DMS & MBA) and
								extensive training in counselling and psychotherapy, combined
								with a wealth of entrepreneurial experience spanning nearly four
								decades, I offer a deep understanding of the unique challenges
								that come with the accounting and tax advisory professions.
							</p>
							<p>
								As an unbiased and supportive business coach, I'm here to
								address the specific needs of accountants and tax advisors. My
								approach is tailored to foster both your professional growth and
								personal fulfilment. I offer a safe, confidential environment
								where you can openly discuss opportunities, tackle challenges,
								and plan for success. We'll navigate together through common
								issues such as team recruitment and motivation, and achieving
								the work-life balance that seems so difficult in this demanding
								field.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Andrew Fenton. Chartered Tax Advisor"
						review="I have been working with Jason for several months and I have found his support and ideas invaluable to me. His suggestions and mentoring have produced instant results and I am looking forward to continuing working with him. I would highly recommend Jason to anyone looking for someone to support their business and provide genuine added value."
						linkedIn="https://www.linkedin.com/in/andrew-fenton-25b54414"
						profilePic={andrewF}
					/>

					<HorizontalTestimonial
						name="Peter Hill. Dominic Hill Associates Ltd. Chartered Accountants."
						review="Try some learning about YOU I set up Dominic Hill Chartered Accountants in 1992. I understand what it is like to be a small business because I am one. Sometimes you need someone outside your business with no pre-conceived ideas to open your mind to find out what you really want to achieve for yourself and also for your business and in that order. Once you have recognised what you want to achieve you can reset the destination in your life sat nav and set out in that direction. You shouldn’t and can’t re-set the sat nav without stopping to breath. Jason gives you the room to breath, think and then….. move Jason has been really motivating and he makes me smile. I really enjoy the meetings. I thoroughly recommend that you start with a 3 month program because like learning anything you need at least 3 sessions to really start to open your eyes. Why should learning about YOU be any different?"
						showLinkedIn="d-none"
						profilePic={dHill}
					/>

					<HorizontalTestimonial
						name="Kolos Tapler. Accountant. No 1 Accountants Ltd"
						review="I was inspired to book a business MOT with Jason as I heard other business owners say how useful it was for them. It might seem contrary to what is expected or even ironic that the owner of a firm of accountants, that look after and advise small business clients, albeit with a financial focus, would himself want a business MOT. The reality is that all of us can get ‘lonely in business’ and benefit from a fresh perspective and a safe space to explore what we really want for ourselves and our team and how we are going to achieve that. My business MOT with Jason took place over one month in which we had three meetings. I had the option to contact between meetings if I had a question, felt stuck or wanted to update Jason. Each meeting explored what is important to me and we agreed action points for which I was held accountable.  I approached our first meeting with an open mind and quickly realised that Jason is very experienced in business and a great listener. I was able to explore with Jason what I really want for myself and my team and celebrate our strengths. Thinking strategically, we were also able to identify many tweaks, changes and improvements to enhance an already successful accountancy practice. I’m very much looking forward to working with Jason going forward and have already recommended several of my own clients for business coaching"
						linkedIn="https://www.linkedin.com/in/kolostapler"
						profilePic={kolos}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a call together</h3>
							<p>
								Whether you are an accountant or a tax advisor, the challenges
								you face within your role are one of a kind. You’re well-versed
								in the language of numbers and understand the importance of
								accuracy, compliance, and strategic financial planning in the
								success of any business. Yet, the journey to professional growth
								often requires more than just financial expertise, it demands a
								holistic approach to your own business vision and goals.
							</p>
							<p>
								Let's schedule a discovery call to get to know each other and
								discuss how business and executive coaching can be tailored
								specifically for yourself as an accountant and tax advisor.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default AccountantsCoachingPage;
